import React, { useState } from "react";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { sizeOfFile } from "app/utils/Utils";
import { Tooltip } from "@mui/material";
import axiosApiInstance from "app/config/axios";
import LinearProgressWithLabel from "app/components/LinearProgressWithLabel";
import {
  MESSAGE_ERROR,
  MESSAGE_ERROR_TOO_LARGE,
  MESSAGE_ERROR_WRONG_TYPE,
} from "app/constants/constants";
import DepotRetour from "./DepotRetour";
import "../../../../App.css";
import BlockUI from "../../../components/BlockUI/BlockUI";
import keycloak from "app/config/keycloak";

const UploadComponent = ({ setClickUpload, clickUpload, setErrorMessage }) => {
  const UPLOAD_FILE_EXT = window.__env__.UPLOAD_FILE_EXT;
  const UPLOAD_FILE_MAX_SIZE = window.__env__.UPLOAD_FILE_MAX_SIZE;
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [progress, setProgress] = React.useState<number>(0);

  const handleUploadFile = (event) => {
    let fileUpload = null;
    if (event?.target?.files) {
      fileUpload = event.target.files[0];
    }
    setSelectedFile(fileUpload);
  };

  function handleSendFile() {
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append("bucketName", keycloak?.tokenParsed?.bucket);
    formData.append("file", selectedFile);

    // send file to back
    axiosApiInstance({
      method: "POST",
      url: "depot/upload",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data.loaded) / data.total));
        setSelectedFile(null);
      },
    })
      .then(() => {
        setClickUpload(true);
        setProgress(0);
      })
      .catch((err) => {
        switch (err?.response?.status) {
          case 413:
            setErrorMessage(MESSAGE_ERROR_TOO_LARGE);
            break;
          case 415:
            setErrorMessage(MESSAGE_ERROR_WRONG_TYPE);
            break;
          default:
            setErrorMessage(MESSAGE_ERROR);
        }
        setClickUpload(true);
        setProgress(0);
      });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
      }}
    >
      <Box
        sx={{
          mt: 5,
          ml: 10,
        }}
      >
        <Grid container spacing={1}>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <CobaltIcon name="upload" />
          </Grid>
          <Grid item>
            <Typography variant="h4"> Importer un fichier</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "60%",
            borderRadius: 2,
            border: `1px dashed var(--primary-color)`,
            bgcolor: "cobalt.white",
            mb: 3,
            p: 4,
          }}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={4} textAlign="center">
              <label htmlFor="upload-file">
                <input
                  style={{ display: "none" }}
                  accept={UPLOAD_FILE_EXT}
                  id="upload-file"
                  type="file"
                  onChange={(event) => {
                    handleUploadFile(event);
                  }}
                  onClick={(event) => {
                    (event.target as HTMLInputElement).value = "";
                  }}
                />
                <Button
                  variant="outlined"
                  aria-label="Sélectionner votre fichier"
                  component="span"
                  sx={{
                    color: "var(--primary-color)",
                    borderColor: "var(--primary-color)",
                    "&.MuiButtonBase-root:hover": {
                      color: "var(--secondary-color)",
                      borderColor: "var(--secondary-color)",
                    },
                  }}
                >
                  Sélectionner votre fichier
                </Button>
              </label>
            </Grid>
            <Grid item md={4} textAlign="center">
              <Typography
                variant="caption"
                style={{ fontSize: "1rem", lineHeight: "22px" }}
              >
                Sélectionner votre fichier{" "}
                {UPLOAD_FILE_EXT &&
                  UPLOAD_FILE_EXT.replace(/,([^,]*)$/, " ou $1")}
                , afin de le déposer sur l’EDS Agoria Santē. Taille maximum
                autorisée : {UPLOAD_FILE_MAX_SIZE}
              </Typography>
            </Grid>

            {selectedFile && (
              <Grid item md={12} sx={{ mt: 5 }}>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item md={4}>
                    <Typography variant="subtitle1">
                      <CobaltIcon name="file-text" />
                      {" " + selectedFile?.name}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="body3">
                      {sizeOfFile(selectedFile?.size)} - {selectedFile?.type}
                    </Typography>
                  </Grid>
                  <Grid item md={1} textAlign="right">
                    <Tooltip title="Annuler" placement="top" arrow>
                      <CobaltIcon
                        id="idButtonCancel"
                        name="checkbox-x"
                        sx={{
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={(e) => {
                          handleUploadFile(e);
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item md={12} sx={{ mt: 5 }} textAlign="center">
                  <Button
                    id="idButtonSend"
                    variant="contained"
                    aria-label="Envoyer"
                    sx={{
                      backgroundColor: "var(--secondary-color)",
                      "&.MuiButtonBase-root:hover": {
                        backgroundColor: "var(--primary-color)",
                      },
                    }}
                    onClick={() => handleSendFile()}
                  >
                    Envoyer
                  </Button>
                </Grid>
              </Grid>
            )}
            {progress > 0 && (
              <Grid item md={12} sx={{ mt: 5, width: "inherit" }}>
                <LinearProgressWithLabel id="idProgessBar" value={progress} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <BlockUI
        blocking={progress === 100 && !clickUpload}
        message={"Le fichier est en cours de stockage ..."}
      />
    </Box>
  );
};

const Depot = () => {
  const [clickUpload, setClickUpload] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
      }}
    >
      {!clickUpload ? (
        <UploadComponent
          setClickUpload={setClickUpload}
          clickUpload={clickUpload}
          setErrorMessage={setErrorMessage}
        />
      ) : (
        <DepotRetour
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setClickUpload={setClickUpload}
        />
      )}
    </Box>
  );
};

export default Depot;
